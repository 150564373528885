@font-face {
    font-family: 'SegoeUI-Regular';
    src: url('../fonts/SegoeUI-Regular/SegoeUI-Regular.eot');
    src: url('../fonts/SegoeUI-Regular/SegoeUI-Regular.svg') format('svg'), url('../fonts/SegoeUI-Regular/SegoeUI-Regular.woff') format('woff'), url('../fonts/SegoeUI-Regular/SegoeUI-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SegoeUI-Semibold';
    src: url('../fonts/SegoeUI-Semibold/SegoeUI-Semibold.eot');
    src: url('../fonts/SegoeUI-Semibold/SegoeUI-Semibold.svg') format('svg'), url('../fonts/SegoeUI-Semibold/SegoeUI-Semibold.woff') format('woff'), url('../fonts/SegoeUI-Semibold/SegoeUI-Semibold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SegoeUI-Bold';
    src: url('../fonts/SegoeUI-Bold/SegoeUI-Bold.eot');
    src: url('../fonts/SegoeUI-Bold/SegoeUI-Bold.svg') format('svg'), url('../fonts/SegoeUI-Bold/SegoeUI-Bold.woff') format('woff'), url('../fonts/SegoeUI-Bold/SegoeUI-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
    color: #262626;
    font-family: 'SegoeUI-Regular' !important;
    font-size: 14px !important;
    background: #ebeef1;
}

a, div, span, h4 {
    transition: all 0.3s ease-in-out !important;
}
.float-left{
    float: left;
}
.float-right{
    float: right;
}
.mb0{
    margin-bottom: 0 !important;
}
.mb7 {
    margin-bottom: 7px !important;
}

.mb20 {
    margin-bottom: 20px !important;
}

.mb10 {
    margin-bottom: 10px !important;
}
.mb15{
    margin-bottom: 15px !important;
}
.mb20 {
    margin-bottom: 20px !important;
}

.mr10 {
    margin-right: 10px !important;
}
.mr20{
    margin-right: 20px !important;
}
.ml20 {
    margin-left: 20px;
}

.mt0{
    margin-top: 0 !important;
}
.mt10 {
    margin-top: 10px !important;
}

.mt15 {
    margin-top: 15px !important;
}

.mt20 {
    margin-top: 20px !important;
}

.mt30 {
    margin-top: 30px !important;
}

.pd0 {
    padding: 0 !important;
}
.pb0{
    padding-bottom: 0 !important;
}
label.subError {
    color: red;
    margin: 20px;
    font-size: 15px;
}
.text-underline{
    text-decoration: underline;
}
.colorMenu span {
    width: 100%;
    border-radius: 10%;
    float: left;
    margin-right: 10px;
}
.colorMenu span.colorLabel {
    height: 11px;
    text-align: center;
    font-size: 11px;
    line-height: 11px;
}

/*.content-left {*/
/*width: 500px;*/
/*padding: 0 10px;*/
/*}*/

/*.ui.button.small {*/
/*padding: 8px 20px;*/
/*}*/

.ui.dimmer {
    background-color: rgba(1, 74, 81, 0.9) !important;
}

.ui.header {
    font-family: 'SegoeUI-Semibold' !important;
}

.bodyColor {
    color: #273140;
}

.primaryColor {
    color: #d67d38 !important;
}

.colorGray {
    color: #707070 !important;
}

.clrGreen {
    color: #6cb043;
    font-weight: 600;
}
.bgMountainMeadow{
    background: #35BA9B;
}
.bgLightMountainMeadow{
    background: #61DDBC;
}
.bgOrange{
    background: #CE6429;
}
.bgLightOrange{
    background: #EECEAF;
}
.bgMahogany{
    background: #d63032;
}
.bgLightMahogany{
    background: #ff7674;
}
.clrRed {
    color: #e60d09;
    font-weight: 600;
}
.txtCenter {
    text-align: center;
}

.bg-color-primary {
    background: #1a89a7 !important;
}

.color-white {
    color: #fff !important;
}
.statusApproved{
    background: #5bb749;
}
.statusDisapproved{
    background: #ec3b38;
}
.statusNew{
    background: #faf2b6;
}
.statusPending{
    background: #8ea8b2;
}
.banner .tooltip {
    position: relative;
    display: inline-block;
    opacity: unset !important;
    text-align: center;
    width: 100%;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 15px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.overflow-scroll {
    max-height: 600px;
    overflow: auto;
}

.modalOverflowUnset {
    overflow: unset !important;
}

.modalOverflowUnset .content {
    overflow: unset !important;
}

.cursorPointer {
    cursor: pointer !important;
}

.displayInline {
    display: inline !important;
}

.displayBlock {
    display: block !important;
}

sub {
    font-size: 12px;
}

.text-uppercase {
    text-transform: uppercase;
}

.borderBottomGray {
    border-bottom: 1px solid #ddd;
}

.maxHeightAuto {
    max-height: unset !important;
    overflow: unset !important;
}
/*------------------------------------------------------------New-CSS-Start-----------------------------------------*/
.ui.selection.dropdown{
    min-height: 25px;
    /*padding: 8px;*/
}
.ui.selection.dropdown > .dropdown.icon{
    padding: 8px;
}
.table-wrapper{
    width: 100%;
    overflow: auto;
}
#RBS-Scheduler-root{
    width: 100% !important;
}
#RBS-Scheduler-root > tbody > tr> td:first-child{
    display: none;
}
table.scheduler-bg-table{
    width: 100%;
}
table.scheduler-bg-table th{
    border-right: none;
}
table.scheduler-bg-table tr{
    border-bottom: none;
}
table.scheduler-bg-table tr th{
    background: none !important;
}
table.scheduler-bg-table tr td{
    background: #fdf9f5;
}
table.scheduler-bg-table tr td:first-child{
    border-left: 1px solid #e9e9e9;
}
.scheduler-view{
    border: none;
    width: 101%;
}
.scheduler-view div:first-child{
    overflow: initial !important;
}
.scheduler-view div:last-child{
    overflow: unset !important;
}
.scheduler-view > div{
    border-bottom: none !important;
}

.scheduler{
    margin: 0;
}

.table-responsive{
    overflow:auto;
}

.fixed-table-header {
    margin-top: 10px;
} 

.fixed-table-header  table {
    position: relative;
}
.fixed-table-header  thead {
    position: sticky;
    top: 0;
}

.sticky-header thead th{
    position: sticky;
    top: 0;
    z-index: 900;
    padding: 5px 5px !important;
}

#RBS-Scheduler-root thead tr td .ant-row-flex{
    justify-content: end;
    position: absolute;
    right: 15px;
    top: 21px;
}
#RBS-Scheduler-root thead tr td .ant-row-flex .ant-col:last-child{
    display: none;
}
.scheduler-view .timeline-event .location {
    font-size: 12px;
    padding: 2px 8px;
    border-radius: 2px;
    box-shadow: inset 1px 1px 1px #00000029;
}
.scheduler-view .timeline-event .event-item{
    height: auto !important;
    padding: 5px 5px;
    border-radius: 3px;
}
.scheduler-view .timeline-event .event-item > span{
    margin-left: 0 !important;
}
.scheduler-view .timeline-event .drName{
    color: #424852;
}

.banner p {
    font-size: 16px;
    border-bottom: 1px solid grey;
    padding: 5px;
    text-transform: capitalize;
}
/*-------------------------------------Left-Panel-----------------------------------------*/
.leftPanel{
    width: 223px;
    height: calc(100% - 60px);
    background: #fff;
    float: left;
    padding-top: 20px;
    overflow: auto;
}
.leftPanel .ui.list .item{
    padding: 11px 15px;
    border-bottom: 1px solid #f2f2f2;
}
.leftPanel .ui.list .item:nth-child(2){
    padding: 2px 15px;
}
.leftPanel .ui.input{
    width: 100%;
}
.leftPanel .ui.search input{
    border: none;
}
.leftPanel h5{
    font-size: 18px;
    color: #232323;
}
.leftPanel h6{
    font-size: 16px;
    color: #888;
}
.content-right{
    padding: 30px 15px 0 15px;
    height: calc(100% - 60px);
    overflow-y: auto;
}
.ui.grid{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.ui.grid > .row{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
/*-------------------------------------Modal-----------------------------------------*/
.ui.modal.timeOffRequest .header{
    background: #eaeaea;
    font-size: 14px !important;
    padding: 13px 20px !important;
}
.ui.modal.timeOffRequest .header .ui.label{
    background: #CE6429;
    color: #fff;
    font-size: 14px;
    border-radius: 12px;
    padding: 5px 12px;
    font-weight: normal;
    margin-left: 20px;
}
.ui.modal.timeOffRequest .ui.labeled.input .label{
    background: none;
    font-size: 14px;
    color: #22252a;
}
.ui.modal.timeOffRequest .ui.labeled.input{
    border-bottom: 1px solid #EAEAEA;
}
.ui.modal.timeOffRequest .ui.labeled.input input{
    text-align: right;
    background: none;
    border: none;
}
.ui.modal.timeOffRequest .ui.labeled.input.right{
    border-bottom: none;
}
.bgGreen {
    background-color: green !important;
}

.bgRed {
    background-color: red !important;
}

.bgOrange {
    background-color: orange !important;
}
.mr5 {
    margin-right: 5px !important;
}
/*.ui.modal.timeOffRequest .ui.labeled.input.right input{*/
/*    text-align: left;*/
/*    background: #EAEAEA;*/
/*}*/
/*.ui.modal.timeOffRequest .ui.labeled.input.right .label{*/
/*    background: #B8B8B8;*/
/*    color: #fff;*/
/*    font-weight: normal;*/
/*}*/
.ui.modal.timeOffRequest .ui.form .field{
    margin-bottom: 20px;
}
/*--------------------------------------------------Custom-Date-Picker-----------------------------------------*/
.customDatePicker .field{
    float: left;
    width: 100%;
}
.customDatePicker .datePickerWrapper{
    width: 100%;
    border-radius: 3px;
    background: #EECEAF;
    border: 1px solid #CE6429;
    float: left;
    padding: 10px;
}
.customDatePicker .ui.input{
    width: calc(50% - 17px) !important;
    float: left;
}
.customDatePicker .ui.input input{
    padding: 4px 1rem;
    border: 1px solid #707070;
}
.customDatePicker p{
    float: left;
    margin: 4px 10px;
}
/*--------------------------------------------------Location-And-Status-----------------------------------------*/
.locationAndStatus input{
    padding: 16px 1rem !important;
    border: 1px solid #61DDBC !important;
    background: #fff !important;
    margin-right: 5px !important;
}
.locationAndStatus .ui[class*="right labeled"].input > input{
    border-right-color: #61DDBC !important;
}
.locationAndStatus .ui.labeled.input.right .ui.dropdown.label{
    background: #35BA9B !important;
}
.anchor{
    font-weight: normal !important;
}
    /*------------------------------------------------------------New-CSS-End-----------------------------------------*/
/*---------------------------------------------------------------Buttons------------------------------------------------*/
.ui.button {
    font-size: 14px !important;
    font-weight: normal;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

.ui.primary.buttons .button, .ui.primary.button {
    background-color: #166b83 !important;
}

.ui.primary.button:hover {
    background-color: #0e5468 !important;
}

.ui.secondary.buttons .button, .ui.secondary.button {
    background-color: #767f88 !important;
}

.ui.secondary.buttons .button:hover, .ui.secondary.button:hover {
    background-color: #6b7580 !important;
}

button:focus {
    outline: none !important;
}

/*---------------------------------------------------------------Heading------------------------------------------------*/
h2 {
    font-family: 'SegoeUI-Regular' !important;
    font-size: 26px;
    margin-bottom: 10px !important;
}

h3 {
    font-family: 'SegoeUI-Semibold';
    font-size: 22px;
}

h5 {
    font-family: 'SegoeUI-Semibold';
    font-size: 16px;
}

/*---------------------------------------------------------------Login------------------------------------------------*/
.login {
    width: 100%;
    height: 100%;
    background-size: cover;
    display: flex;
    align-items: center;
    text-align: center;
}

.login h1 {
    font-size: 28px !important;
    font-weight: lighter !important;
    color: rgba(0, 0, 0, 0.54) !important;
}

.loginLogo {
    margin: 80px;
}

.login .google {
    font-size: 16px !important;
    padding: 0 20px !important;
    width: 260px;
}

.contentCenter {
    width: 100%;
}

.login .sectionLeft {
    height: 100vh;
    background: url("../images/loginLeftBg.jpg") no-repeat;
    background-size: cover;
    background-position: 50%;
}

.login .sectionRight {
    background: url("../images/loginRightBg.png") no-repeat;
    background-position: 100% 100%;
    background-color: #fff;
}

.login .sectionRight .row {
    height: 100%;
    align-items: center;
}

/*-------------------------------------------------------------------Header-----------------------------------------*/
.logo {
    width: 200px;
    padding: 38px 0;

}

.cdp-udr .ui.basic.segment > .ui.secondary.menu {
    background: #fff;
    height: 60px;
    color: #273140;
    box-shadow: 0px 2px 8px #ddd;
    margin: 0;
    width: 100%;
}

.cdp-udr .ui.secondary.menu .item {
    color: #273140;
    padding: 21px 25px;
    margin: 0;
}

.cdp-udr .ui.secondary.menu .item.toggle {
    padding: 20px 22px;
    border-radius: 0;
    background: #fff;
}

.cdp-udr .ui.secondary.menu .item.toggle:hover {
    background: #ebeef1;
}

.cdp-udr .ui.secondary.menu .item.toggle .icon {
    font-size: 20px;
    color: #d16f26;
}

.cdp-udr .ui.secondary.menu .item:hover {
    color: #273140 !important;
}

.cdp-udr .ui.secondary.menu .icon.setting {
    width: auto;
}

.cdp-udr .ui.secondary.menu .ui.dropdown:hover {
    background: #fff;
}

.cdp-udr .ui.secondary.menu .ui.dropdown .menu {
    border-radius: 0 0 4px 4px !important;
    left: -120px !important;
    margin-top: 0 !important;
    width: 185px;
}

.cdp-udr .ui.secondary.menu .ui.dropdown .menu .menu {
    margin-top: 35px !important;
}

.cdp-udr .ui.secondary.menu .ui.dropdown .menu .item {
    border-radius: 0;
}

.cdp-udr .ui.secondary.menu .menu .item.innerDropdown {
    padding: 0 !important;
}

.cdp-udr .ui.secondary.menu .menu .item.innerDropdown .menu {
    font-size: .9rem !important;
}

.cdp-udr .ui.secondary.menu .item.innerDropdown > div:first-child {
    padding: 0.78571429em 1.14285714em !important;
    width: 100%;
}

.cdp-udr .ui.secondary.menu .ui.dropdown .menu .item + .item {
    border-top: 1px solid #e1e1e1;
}

.cdp-udr .ui.secondary.menu .dropdown.user {
    padding: 17px 16px;
}

.cdp-udr .ui.secondary.menu .dropdown.user .text {
    margin-top: 4px;
}

.cdp-udr .ui.secondary.menu .ui.floating.label {
    top: 7px !important;
    left: 52px !important;
    min-width: 20px !important;
    min-height: 20px !important;
    padding: 4px !important;
}

.cdp-udr .ui.secondary.menu .ui.compact.menu {
    background: none;
    border: none;
    box-shadow: none;
}

.cdp-udr .ui.menu .right.menu .dropdown:last-child .menu {
    right: 5px !important;
}

/*-----------------------------------------------------------List------------------------------------------------*/
.ui.horizontal.list.topValues > .item {
    margin-left: 50px;
    padding: 0 !important;
}

.ui.horizontal.list.topValues > .item span {
    cursor: pointer;
}

.ui.horizontal.list.topValues > .item span:hover {
    color: #0e5468;
}

/*-----------------------------------------------------------Calendar------------------------------------------------*/
.calendar-container .rbc-calendar {
    min-height: 700px
}

.rbc-calendar .rbc-header {
    font-size: 15px;
    text-transform: uppercase;
    background: #fafafa;
    padding: 13px 4px !important;
}

.rbc-calendar .rbc-header > a {
    min-height: 52px;
    display: block;
}

.rbc-calendar .rbc-month-view {
    border-radius: 4px;
}

.rbc-calendar .rbc-off-range-bg {
    background: #fafafa;
}

.rbc-calendar .rbc-date-cell {
    text-align: left;
    padding-left: 5px;
}

.rbc-calendar .rbc-date-cell a {
    font-family: 'SegoeUI-Semibold';
}

.rbc-calendar .rbc-toolbar .rbc-toolbar-label {
    font-family: 'SegoeUI-Semibold';
    font-size: 22px;
    margin-left: 15px;
    text-align: left;
}

.rbc-calendar .rbc-toolbar .rbc-btn-group button {
    cursor: pointer;
    font-size: 14px;
}

.rbc-calendar .rbc-toolbar .rbc-btn-group:first-child button + button {
    float: left;
    padding: 0.78571429em 0.78571429em 0.78571429em;
    font-size: 0;
    height: 38px;
    width: 38px;
}

.rbc-calendar .rbc-toolbar .rbc-btn-group:first-child button + button:after {
    content: '';
    font-family: Icons;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    font-size: 18px !important;
}

.rbc-calendar .rbc-toolbar .rbc-btn-group:first-child button:first-child {
    border-radius: 4px;
    margin-left: 10px;
    font-size: 14px;
    line-height: 1rem;
    padding: 12px 20px;
    background-color: #166b83 !important;
    color: #fff;
    border: none;
}

.rbc-calendar .rbc-toolbar .rbc-btn-group:first-child button:first-child:hover {
    background-color: #0e5468 !important;
}

.rbc-calendar .rbc-toolbar .rbc-btn-group:first-child button:nth-child(2) {
    border-radius: 4px 0 0 4px;
    margin-left: 1px;
}

.rbc-calendar .rbc-toolbar .rbc-btn-group:first-child button:nth-child(2):after {
    content: '';
}

.rbc-calendar .rbc-toolbar {
    margin-bottom: 15px;
    margin-top: 15px;
}

.rbc-calendar .rbc-toolbar button:active:hover,
.rbc-calendar .rbc-toolbar button.rbc-active:hover,
.rbc-calendar .rbc-toolbar button:active:focus,
.rbc-calendar .rbc-toolbar button.rbc-active:focus {
    background-color: #0e5468 !important;
    color: #fff;
    outline: none;
    border-color: #0e5468;
}

.rbc-calendar .rbc-toolbar button:active, .rbc-calendar .rbc-toolbar button.rbc-active {
    background-color: #166b83 !important;
    color: #fff;
    box-shadow: none;
    border-color: #166b83;
}

.rbc-calendar .rbc-toolbar .rbc-btn-group:last-child button {
    font-size: 14px;
    line-height: 1rem;
    padding: 11px 15px
}

.rbc-calendar .rbc-event {
    border-radius: 0 !important;
    background-color: #f7fafb;
    color: #000;
    font-size: 11px !important;
}

.rbc-month-row {
    overflow: unset !important;
}

.rbc-calendar .rbc-event .rbc-event-content {
    font-family: 'SegoeUI-Semibold';
    overflow-x: auto;
    overflow: unset;
    position: relative;
}

.rbc-row-segment .rbc-event-content {
    white-space: unset !important;
}

.rbc-row-segment .rbc-event-content .notes {
    float: right;
}

.rbc-calendar .rbc-event .rbc-event-content span {
    font-family: 'SegoeUI-Regular';
}

.rbc-agenda-view > .rbc-agenda-table {
    border-bottom: none !important;
}

.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
    border-bottom: none !important;
    padding-left: 5px !important;
}

.item:has(span.test) {
    background: #ff0000;
}

/*-----------------------------------------------------------Tabs------------------------------------------------*/
.ui.pointing.secondary.menu {
    background: transparent;
    box-shadow: none;
    border-bottom: none !important;
    margin-bottom: 20px;
    border-radius: 4px 4px 0 0;
    z-index: 1;
}

.ui.pointing.secondary.menu .item {
    padding: 20px 60px !important;
    background: #fff !important;
    border-bottom: 3px solid transparent !important;
}

.ui.pointing.secondary.menu .item:hover {
    background: #fff !important;
    color: #000;
}

.ui.pointing.secondary.menu .item:hover .icon {
    opacity: 1;
}

.ui.pointing.secondary.menu .item.active,
.ui.secondary.pointing.menu .active.item:hover {
    border-bottom: 3px solid #ce6429 !important;
    background: #fff !important;
}

.ui.pointing.secondary.menu .item:first-child {
    border-radius: 4px 0 0 0;
}

.ui.pointing.secondary.menu .item:last-child {
    border-radius: 0 4px 0 0;
}

.ui.pointing.secondary.menu .item + .item,
.ui.pointing.secondary.menu .item + .item.active:hover {
    border-left: 1px solid rgba(34, 36, 38, 0.15) !important;
}

.ui.pointing.secondary.menu .item .icon {
    color: #ce6429;
    opacity: 0.7;
    margin-right: 12px;
}

.ui.pointing.secondary.menu .item.active .icon {
    color: #ce6429;
    opacity: 1;
}

.cdp-udr .ui.secondary.menu .logoSmall {
    margin: 8px 0;
    display: none;
}

.ui.segment.tabContent {
    background: none;
    padding-right: 0 !important;
    position: static !important;
}


@media print
{
    body * { visibility: hidden; }
    .containerMain * { visibility: visible; }
    .containerMain { position: absolute; top: 40px; left: 30px; }
}

/*@media print {*/
/*    body, html, .containerMain {*/
/*        width: 100%;*/
/*        background-color: white !important;*/
/*    }*/
/*    .containerMain {*/
/*        margin-left: 0 !important;*/
/*        display: inline !important;*/
/*        height: 100%;*/
/*    }*/
/*}*/
/*-----------------------------------------------------------sidebar------------------------------------------------*/
.containerMain {
    padding: 30px 30px 0;
    overflow: auto;
    height: calc(100% - 60px);
}

.cdp-udr .ui.segment.pushable {
    float: left;
    width: 100%;
    height: 100%;
    /*padding-top: 60px !important;*/
    z-index: 1;
}

.ui.left.sidebar, .ui.right.sidebar {
    -webkit-box-shadow: 0px 0px 20px rgba(34, 36, 38, 0.15);
    box-shadow: 0px 0px 20px rgba(34, 36, 38, 0.15);
}

.cdp-udr .ui.visible.thin.left.sidebar ~ .fixed, .cdp-udr .ui.visible.thin.left.sidebar ~ .pusher {
    transform: translate3d(300px, 0px, 0px);
}

.cdp-udr .ui.thin.left.sidebar ~ .fixed, .cdp-udr .ui.thin.left.sidebar ~ .pusher {
    transform: translate3d(66px, 0px, 0px);
}

.cdp-udr .ui.visible.thin.left.sidebar ~ .pusher .ui.basic.segment {
    padding-right: 300px;
    transition: all 0.66s ease 0s;
    -webkit-transition: all 0.66s ease 0s;
}

.cdp-udr .ui.left.push.sidebar {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
}

.cdp-udr .ui.visible.push.sidebar {
    -webkit-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.cdp-udr .ui.segment {
    border-radius: 0;
    border: none;
    box-shadow: none;
    padding: 0 66px 0 0;
    position: absolute;
    width: 100%;
    height: 100%;
}

.cdp-udr .ui.thin.left.sidebar.visible {
    width: 300px;
    background: #fff;
}

.cdp-udr .ui.thin.left.sidebar {
    width: 66px;
    background: #fff;
    overflow-x: hidden;
}

.cdp-udr .ui.thin.left.sidebar::-webkit-scrollbar {
    display: none;
}

.cdp-udr .ui.thin.left.sidebar.visible .logo {
    width: 200px;
    padding: 35px 0;
}

.cdp-udr .ui.thin.left.sidebar .logo {
    width: 210px;
    padding: 38px 0 38px 7px;
}

.cdp-udr .ui.thin.left.sidebar .vertical.menu {
    background: none;
    width: 100%;
    border: none;
    box-shadow: none;
}

.cdp-udr .ui.thin.left.sidebar.visible .vertical.menu .item {
    font-family: 'SegoeUI-Regular' !important;
    font-size: 14px;
    color: #262626;
    text-transform: uppercase;
    text-align: left;
    padding: 20px;
}

.cdp-udr .ui.thin.left.sidebar.visible .vertical.menu .item .item {
    text-transform: capitalize;
    border-left: 1px solid #E6B390;
    margin-left: 5px;
    padding: 15px 20px;
}

.cdp-udr .ui.thin.left.sidebar.visible .vertical.menu .item .item.active {
    background-color: #e6b288;
    border-left: 1px solid #D06F2A;
}

.cdp-udr .ui.thin.left.sidebar .vertical.menu .item {
    font-family: 'SegoeUI-Regular' !important;
    font-size: 0;
    color: #262626;
    text-transform: uppercase;
    text-align: right;
    padding: 20px;
}

.cdp-udr .ui.thin.left.sidebar.visible .vertical.menu .item i.icon {
    font-size: 16px !important;
    padding-right: 15px;
    display: inline;
}

.cdp-udr .ui.thin.left.sidebar .vertical.menu .item i.icon {
    font-size: 16px !important;
    padding-right: 10px;
    display: inline;
}

.cdp-udr .ui.thin.left.sidebar .vertical.menu .item i.icon.dropdown {
    padding-right: 0;
}

.cdp-udr .ui.thin.left.sidebar .vertical.menu .item .title {
    display: inline !important;
}

.cdp-udr .ui.thin.left.sidebar .vertical.menu .item .label {
    height: 22px;
    background: #d67d38;
    padding: 5px 7px;
    border-radius: 11px;
    min-width: 22px;
    margin-top: -5px;
}

.cdp-udr .pushable > .pusher {
    overflow: auto;
    background: #ebeef1;
}

.cdp-udr .leftMenu.ui.accordion > .title {
    float: left;
    width: 100%;
    padding: 0;
}

.cdp-udr .leftMenu.ui.accordion > a,
.cdp-udr .leftMenu.ui.accordion > .title span {
    float: left;
    width: 100%;
    color: #232323;
    text-transform: uppercase;
    text-align: left;
}

.cdp-udr .leftMenu.ui.accordion > a {
    font-size: 0;
    text-align: center;
    padding: 15px 15px 20px 15px;
}

.cdp-udr .ui.sidebar.visible .logo {

}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > a,
.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .title span {
    padding: 16px 15px;
}

.cdp-udr .leftMenu.ui.accordion > .title span {
    font-size: 0;
    text-align: left;
    padding: 15px 5px 20px 22px;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > a,
.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .title span {
    font-size: 14px;
    text-align: left;
}

.cdp-udr .leftMenu.ui.accordion > a:hover,
.cdp-udr .leftMenu.ui.accordion > .title span:hover {
    text-decoration: none;
    background: #ebeef1;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > a:hover,
.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .title span:hover {
    padding-left: 20px;
}

.cdp-udr .leftMenu.ui.accordion > a.active a,
.cdp-udr .leftMenu.ui.accordion > .title.active span {
    color: #000;
}

.cdp-udr .leftMenu.ui.accordion > .title.active span i.icon.angle.down {
    transform: rotate(180deg);
    margin-top: 8px !important;
}

.cdp-udr .leftMenu.ui.accordion > a.active:hover,
.cdp-udr .leftMenu.ui.accordion > .title.active span:hover {
    background: #eeceaf;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .title.active span:hover,
.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > a.active:hover {
    padding-left: 15px;
}

.cdp-udr .leftMenu.ui.accordion > a > .icon,
.cdp-udr .leftMenu.ui.accordion > .title .icon {
    margin-right: 0;
    font-size: 16px;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > a > .icon,
.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .title .icon {
    margin-right: 15px;
    font-size: 16px;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .title .icon.angle {
    font-size: 14px;
    margin-top: 0;
    float: right;
    margin-right: 0 !important;
    transition: all 0.3s ease-in-out !important;
}

.cdp-udr .leftMenu.ui.accordion > .title .icon.angle.down {
    font-size: 12px;
    margin-top: 4px;
    float: right;
    margin-right: 0 !important;
    transition: all 0.3s ease-in-out !important;
}

.cdp-udr .leftMenu.ui.accordion > .title > .icon.dropdown {
    display: none;
}

.cdp-udr .leftMenu.ui.accordion > .title.active,
.cdp-udr .leftMenu.ui.accordion > a.active {
    border-left: 5px solid #ce6429;
    background: #eeceaf;
}

.cdp-udr .leftMenu.ui.accordion > .content .ui.list {
    float: left;
    width: 100%;
    background: #eeceaf;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .content .ui.list {
    padding-left: 28px;
}

.cdp-udr .leftMenu.ui.accordion > .content .ui.list {
    padding-left: 0;
}

.cdp-udr .leftMenu.ui.accordion > .content .ui.list .item {
    color: #232323;
    text-transform: capitalize;
    text-align: left;
    padding: 12px 15px;
    border-left: 1px solid #E6B390;
}

.cdp-udr .leftMenu.ui.accordion > .content .ui.list .item:hover {
    background: #ecc2a0;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .content .ui.list .item {
    font-size: 14px;
}

.cdp-udr .leftMenu.ui.accordion > .content .ui.list .item {
    font-size: 0;
}

.cdp-udr .leftMenu.ui.accordion > .content .ui.list .item > .icon {
    margin-right: 10px;
}

.cdp-udr .leftMenu.ui.accordion > .content .ui.list .item:after {
    display: none;
}

.cdp-udr .leftMenu.ui.accordion > .content .ui.list .item.active {
    border-left: 1px solid #D06F2A;
    background-color: #e6b288;
    color: #000 !important;
}

.cdp-udr .leftMenu.ui.accordion > .content .ui.list .item .icon {
    font-size: 15px !important;
    color: #232323;
    line-height: 18px;
    margin: 0 auto !important;
    padding-left: 0;
    float: none;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .content .ui.list .item .icon {
    float: left;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .content .ui.list .item .icon {
    line-height: normal;
    margin-right: 10px !important;

}

.cdp-udr .leftMenu.ui.accordion > a > .icon.caredelta {
    background: url("../images/careDelta.png") no-repeat;;
}

.cdp-udr .leftMenu.ui.accordion > a > .icon.commonBond {
    background: url("../images/commonBond.png") no-repeat;;
}

.icon.iconDentist {
    background: url("../images/iconDentistOutline.png") no-repeat;
}

/*-------------------------------------------------------------------Table-----------------------------------------*/

.table {
    border-radius: 0;
    border: none;
    font-size: 13px;
    margin-top: 15px;
}

.table td {
    border-top: none !important;
    padding: 15px 0.75rem;
}

.table thead th {
    font-family: 'SegoeUI-Semibold';
    border-top: none;
    color: #000 !important;
    background: #EECEAF !important;
    border-radius: 0 !important;
    border-bottom: 3px solid #EECEAF !important;
    vertical-align: middle !important;
    padding: 2px 0.75rem;
    min-width: 100px;
}

.table thead th > div {
    align-items: center;
    display: flex;
    align-content: center;
}

.table thead th.active {
    border-bottom: 3px solid #ce6429 !important;
}

.table thead i.icon {
    color: #ce6429;
    opacity: 0.9;
    margin-left: 5px;
}

.table thead th.active i.icon {
    opacity: 1;
}

.table thead th:hover i.icon {
    color: #ce6429;
    opacity: 1;
}

.table tbody tr:hover {
    background: #fafcfd;
    color: #000;
}

.tr-reduce{
    height: 100px;
    overflow: hidden;
}
.tr-expand{
    height: auto;
}

.table-striped tbody tr:nth-of-type(even) {
    background-color: #fff;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #FCF5EF !important;
}

.table-striped tbody tr:nth-of-type(odd):hover {
    background-color: #f9eadd !important;
}


/* table stripped members table */
.table-striped2 tbody tr {
    border-bottom: #d16f26  solid 2px;
}


/*.table thead th a {*/
/*color: #273140;*/
/*display: block;*/
/*padding: 15px 0 15px 10px;*/
/*border-bottom: 3px solid transparent;*/
/*}*/
/*.table thead th a:focus {*/
/*color: #d67d38;*/
/*border-bottom: 3px solid #d67d38;*/
/*text-decoration: none;*/
/*}*/
/*.table thead th a:focus i.icon {*/
/*color: #d67d38;*/
/*}*/
/*.table thead th:last-child a {*/
/*padding: 15px 10px;*/
/*}*/

.table tbody tr:hover {
    background: #f8fafb;
    color: #000;
}

.table tfoot td {
    background: none !important;
    border-bottom: 1px solid #aaacb0;
    border-top: 1px solid #aaacb0;
    border-radius: 0 !important;
}

.table .ui.button {
    padding: 8px 20px;
}

/*.table.table-border thead th:first-child {*/
/*padding-left: 18px;*/
/*}*/

/*.table.table-border tbody tr {*/
/*border-left: 36px solid transparent;*/
/*}*/

/*.table.table-border tbody tr.border-green {*/
/*border-left: 36px solid #c5e1a5;*/
/*}*/

/*.table.table-border tbody tr.border-yellow {*/
/*border-left: 36px solid #fee997;*/
/*}*/

/*.table.table-border tbody tr.border-red {*/
/*border-left: 36px solid #ffb0a8;*/
/*}*/

.table.tableStyle th {
    padding-left: 10px;
}

.table.fc-table, .table.fc-table td {
    border: 1px solid #DDDDDD;
}

.table.fc-table td {
    padding: 6px 0.75rem;
}

/*.TableHeaderFixed tbody {*/
/*display: block;*/
/*height: 400px;*/
/*overflow: auto;*/
/*}*/

/*.TableHeaderFixed thead, .TableHeaderFixed tbody tr {*/
/*display: table;*/
/*width: 100%;*/
/*table-layout: fixed;*/
/*}*/

/*.TableHeaderFixed thead {*/
/*width: calc(100% - 1em)*/
/*}*/

/*.TableHeaderFixed td {*/
/*border-top: none !important;*/
/*word-break: break-word;*/
/*}*/

/*.TableHeaderFixed th {*/
/*white-space: pre-wrap !important;*/
/*}*/

.TableHeaderFixed thead th, .TableHeaderFixed {
    position: sticky;
    top: 0;
    z-index: 10;
}

.ui.pagination.menu {
    box-shadow: none;
    border: none;
}

.ui.pagination.menu .item {
    border-radius: 4px !important;
    border: 1px solid #dededf;
    color: #d67d38;
}

.ui.pagination.menu .item:before {
    content: "";
}

.ui.pagination.menu .item:hover {
    background: #d67d38;
    color: #fff;
}

.ui.pagination.menu .item .item.active {
    background: #d67d38;
    color: #fff;
}

.ui.pagination.menu .item + .item {
    margin-left: -1px;
}

/*.head-pagination {*/
/*margin: 0;*/
/*}*/

/*.head-pagination li {*/
/*float: left;*/
/*}*/

/*.head-pagination li .ui.button {*/
/*background: #fff;*/
/*border: 1px solid #dededf;*/
/*width: 37px;*/
/*height: 37px;*/
/*padding: 0;*/
/*}*/

/*.head-pagination li p {*/
/*font-size: 13px;*/
/*margin-top: 9px;*/
/*}*/

/*.head-pagination li + li .ui.button {*/
/*margin-left: -1px;*/
/*}*/

/*-----------------------------------------------------------Modal--------------------------------------------------*/
.ui.inverted.dimmer .ui.loader:after {
    border-color: #FFFFFF transparent transparent !important;
}

.ui.inverted.dimmer .ui.loader {
    color: #fff !important;
}

.ui.modal {
    bottom: auto;
    font-size: 13px !important;
}

.ui.modal .header {
    color: #262626;
    font-family: 'SegoeUI-Semibold' !important;
    font-size: 20px !important;
    padding: 18px 20px !important;
    border-color: #e1e1e1;
    border-radius: 4px 4px 0 0;
    font-weight: normal !important;
}

.ui.modal .close {
    position: static !important;
    color: #273140 !important;
    margin: 12px 13px 11px 0 !important;
    padding: 7px !important;
    float: right;
}

.ui.modal .content {
    max-height: calc(100% - 135px);
    overflow: auto;
}

.ui.modal.familyView > .content {
    padding: 1.5rem .5rem !important;
}

.ui.modal.familyView .actions {
    display: none;
}

.ui.modal .content .content-middle {
    padding: 40px 20px;
}

.ui.modal .actions {
    padding: 12px 20px;
    background: #fff;
    border-top: 1px solid #e1e1e1;
    border-radius: 0 4px 4px;
    float: left;
    width: 100%;
}

.ui.modal .actions .button {
    margin-bottom: 0;
}

.ui.modal .formStyle label {
    text-transform: uppercase;
    font-size: 12px !important;
    font-weight: 700;
    margin-bottom: 5px;
    display: block;
}

.ui.mini.modal {
    width: 400px !important;
}

.ui.mini.modal label {
    min-width: 30%;
}

.ui.mini.modal .ui.input, .ui.mini.modal .ui.form .inline.field > .selection.dropdown {
    width: 66% !important;
}

.ui.mini.modal .inline.field .field {
    width: 66%;
    display: inline-block;
}

.ui.mini.modal .inline.field .field .ui.input {
    width: 100% !important;
}

.ui.mini.modal .inline.field .react-datepicker__input-container {
    width: 66%;
}

/*----------------------------------------------------------Card--------------------------------------------------*/
.cdp-udr .ui.card {
    width: 100%;
    box-shadow: none;
    margin-bottom: 20px !important;
    margin-top: 0 !important;
    border-radius: 4px;
}

.cdp-udr .ui.card .content {
    padding: 15px;
    border-top: 1px solid #e1e1e1;
}

.cdp-udr .ui.card .content .header {
    font-family: 'BentonSans-Bold';
    font-size: 18px !important;
    color: #273140;
    font-weight: normal !important;
}

.cdp-udr .ui.card .actions {
    padding: 12px 20px;
    min-height: 62px;
    background: #f2f2f2;
    border-top: 1px solid #e1e1e1;
    border-radius: 0 4px 4px;
}

.cdp-udr .ui.card .actions label {
    margin-bottom: 0;
    font-weight: bold;
}

.cdp-udr .ui.card .extra {
    border-top: 1px solid #e1e1e1 !important;
}

.cdp-udr .ui.card.patientDetails label {
    font-size: 13px !important;
    margin-bottom: 2px;
}

.cdp-udr .ui.card.patientDetails h4 {
    font-family: 'SegoeUI-Bold';
    font-size: 18px;
    color: #00838f;
    margin-top: 0;
}

.cdp-udr .ui.card.patientDetails .icon.user.circle {
    color: #00838f;
}

.cdp-udr .ui.card.patientDetails .ui.fitted.divider {
    margin: 24px 0;
}

.cdp-udr .ui.card.patientDetails .row:nth-child(n+2) .col-6:first-child, .cdp-udr .ui.card.patientDetails .row:nth-child(n+2) .col-8 {
    padding-left: 90px;
}

.cdp-udr .ui.card.patientDetails .row .col-6:nth-child(n+2) {
    border-left: 1px solid #e1e1e1;
    padding-left: 20px;
}

.cdp-udr .ui.card.patientDetails .row .col-4:nth-child(n+2) {
    border-left: 1px solid #e1e1e1;
    padding-left: 20px;
}

/*.cdp-udr .ui.card.attachments .thumbnail {*/
/*margin-bottom: 20px;*/
/*border-radius: 4px;*/
/*overflow: hidden;*/
/*position: relative;*/
/*height: 176px;*/
/*text-align: center;*/
/*border: 1px solid #e1e1e1;*/
/*align-items: center;*/
/*display: flex;*/
/*}*/

/*.cdp-udr .ui.card.attachments .thumbnail:hover .overlay {*/
/*display: block;*/
/*}*/

/*.cdp-udr .ui.card.attachments .thumbnail img {*/
/*margin: 0 auto;*/
/*}*/

/*.cdp-udr .ui.card.attachments .thumbnail.preview {*/
/*border: none;*/
/*border-radius: 0 0 4px 4px;*/
/*height: auto;*/
/*}*/

/*.cdp-udr .ui.card.attachments .overlay {*/
/*background: rgba(0, 0, 0, 0.7);*/
/*width: 100%;*/
/*height: 100%;*/
/*position: absolute;*/
/*z-index: 1;*/
/*display: none;*/
/*}*/

.cdp-udr .ui.card.mb0 {
    margin-bottom: 0 !important;
}

/*.ui.card.attachments .overlay a {*/
/*color: #fff;*/
/*position: relative;*/
/*top: 44%;*/
/*}*/

/*.ui.card.attachments.Patients .row .col-sm-6 {*/
/*max-width: 33.33% !important;*/
/*flex: auto !important;*/
/*}*/

/*.ui.card.attachments.Patients .thumbnail {*/
/*height: 100px !important;*/
/*}*/

/*-----------------------------------------------------------scheduler------------------------------------------------*/
.rbc-day-slot .rbc-event-label {
    visibility: hidden;
}

/*-----------------------------------------------------------Others------------------------------------------------*/
.ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before, .ui.toggle.checkbox input ~ .box:after, .ui.toggle.checkbox input ~ label:after {
    border: 1px solid rgba(34, 36, 38, 0.15) !important;
}

.ui.toggle.checkbox input:checked ~ .box:before, .ui.toggle.checkbox input:checked ~ label:before {
    border: none !important;
}

/*.field.mt-3 {*/
/*margin: 3px;*/
/*}*/

/*.thumbnail-image {*/
/*align-items: center;*/
/*border: 1px solid #e1e1e1;*/
/*border-radius: 4px;*/
/*display: flex;*/
/*height: 176px;*/
/*margin: 20px;*/
/*overflow: hidden;*/
/*position: relative;*/
/*text-align: center;*/
/*}*/

.ui.text.loader {
    padding-top: 20px !important;
}

.fc-calander .rbc-calendar .rbc-event {
    background: none !important;
}

.rbc-time-content {
    border-top: 1px solid #ddd !important;
}

.card .card-header {
    padding: 15px;
    border-bottom: 1px solid #dadad9;
    border-radius: 4px 4px 0 0;
    background: #fff;
}

h3.headingSub {
    /*font-family: 'BentonSans-Bold';*/
    font-size: 18px;
    color: #1a89a7;
    margin: 0;
    text-transform: uppercase;
    font-weight: bold;
}

.rc-collapse > .rc-collapse-item > .rc-collapse-header {
    height: 38px;
    line-height: 38px;
    text-indent: 16px;
    color: #666;
    cursor: pointer;
}

.rc-collapse > .rc-collapse-item {
    border-top: 1px solid #d9d9d9;
}

.rc-collapse {
    background-color: #f7f7f7;
    border-radius: 3px;
    border: 1px solid #d9d9d9;
}

.patientInfo .form-group {
    margin-bottom: 10px !important;
}

/*.patientInfo .rc-collapse{*/
/*margin-top: 5px !important;*/
/*}*/
.patientInfo .rc-collapse-item-active .rc-collapse-header {
    background: #d7e7ea;
}

.patientInfo .rc-collapse-content-box .table tr:first-child td {
    border-top: none !important;
}

.patientInfo .rc-collapse {
    background: #f6f6f6 !important;
}

.rc-collapse {
    background-color: #f7f7f7;
    border-radius: 3px;
    border: 1px solid #d9d9d9;
}

.rc-collapse-anim-active {
    transition: height 0.2s ease-out;
}

.rc-collapse > .rc-collapse-item {
    border-top: 1px solid #d9d9d9;
}

.rc-collapse > .rc-collapse-item:first-child {
    border-top: none;
}

.rc-collapse > .rc-collapse-item > .rc-collapse-header {
    height: 38px;
    line-height: 38px;
    text-indent: 16px;
    color: #666;
    cursor: pointer;
}

.rc-collapse > .rc-collapse-item > .rc-collapse-header .arrow {
    display: inline-block;
    content: '\20';
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-left: 4px solid #666;
    vertical-align: middle;
    margin-right: 8px;
}

.rc-collapse > .rc-collapse-item-disabled > .rc-collapse-header {
    cursor: not-allowed;
    color: #999;
    background-color: #f3f3f3;
}

.rc-collapse-content {
    overflow: hidden;
    /*color: #666;*/
    padding: 0 16px;
    background-color: #fff;
}

.rc-collapse-content > .rc-collapse-content-box {
    margin-top: 16px;
    margin-bottom: 16px;
}

.rc-collapse-content-inactive {
    display: none;
}

.rc-collapse-item:last-child > .rc-collapse-content {
    border-radius: 0 0 3px 3px;
}

.rc-collapse > .rc-collapse-item-active > .rc-collapse-header .arrow {
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid #666;
    margin-right: 6px;
}

.patientInfo p {
    font-family: 'SegoeUI-Semibold';
    font-size: 16px;
    margin-bottom: 0;
    padding-right: 5px;
}

.color-secondary {
    color: #d58234;
}

.leftSection {
    width: 350px;
    float: left;
    margin-right: 20px;
}

.rightSection {
    float: left;
    width: calc(100% - 370px);
}

.BCTable .scroller {
    max-height: 420px;
    overflow: auto;
}

.scrolling.content.patientFamilyView {
    padding: 1.5rem .5rem !important;
}

.card {
    background: #fff;
    border-radius: 4px;
    padding: 15px;
    /*border-bottom: 1px solid #d58234;*/
}

.card .card-content {
    padding: 15px;
}

/*.familyView, .familyView .scrolling {*/
/*background: #edeff3 !important;*/
/*}*/
/*.familyView .card .card-header {*/
/*background: #fff;*/
/*}*/
.tableCustomStyle thead th {
    text-transform: uppercase;
    border-bottom: none !important;
    height: 44px;
}

.tableCustomStyle {
    margin-top: 0 !important;
}

.tableCustomStyle tbody tr:nth-child(2n) {
    background: #fcf5ef;
}

.patientInfo tr td {
    border-top: 1px solid #ececec !important;
    padding: 5px 0 !important;
}

.patientInfo tr:first-child td {
    border-top: none !important;
}

.patientInfo tr:first-child td .table tr td {
    border-top: 1px solid #ececec !important;
}

.leftSection .col-12, .leftSection .wd100 {
    width: 100%;
}

.patientInfo .col-12 {
    padding-left: 15px;
    padding-right: 15px;
}

.patientInfo table {
    margin-top: 0px;
}

.text-center {
    text-align: center !important;
}

.p-1{
    padding: 1em !important;
}

.pb-0{
    padding-bottom: 0 !important;
}

.text-red {
    color: #f31e05 !important;
}

.text-green {
    color: #68c802 !important;
}

.text-grey {
    color: grey !important;
}

.phoneTolTipOuter a {
    color: #337ab7 !important;
    text-decoration: none;
}

.childPage-phoneTolTip {
    position: relative !important;
    left: 0 !important;
    width: 340px;
}

.phoneTolTip {
    position: absolute;
    left: 100%;
    background: #fff;
    width: 100%;
    z-index: 9;
    margin-top: 5px;
}

.phoneTolTipImg:first-child {
    border-top: none;
}

.phoneTolTipImg {
    border-top: 1px solid #ececec;
}

.childPage-phoneTolTip .phoneTolTipImg img {
    margin: 10px;
}

.phoneTolTipImg img {
    margin: 15px;
    cursor: pointer;
    width: 15px;
}

.patientInfo p {
    font-family: 'SegoeUI-Semibold';
    font-size: 16px;
    margin-bottom: 0;
    padding-right: 5px;
}

.phoneTolTipP {
    float: right;
    margin: 8px 0 !important;
    width: 135px;
    line-height: 14px;
    margin-bottom: 10px;
    font-size: 13px !important;
    text-align: right;
}

.patientInfo label {
    display: inline-block;
    max-width: 100%;
    font-weight: bold;
}

/*-----------------------------------------------------------Practices------------------------------------------------*/
.ui.card.practices {
    width: calc(25% - 15px);
    float: left;
    margin-right: 15px;
}

.ui.card.practices:nth-child(4n+1) {
    clear: both;
}

.practices .imgHolder {
    border: 1px solid #ededed;
    border-radius: 4px;
    height: 202px;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
}

.practices h4 {
    font-family: 'SegoeUI-Semibold';
    font-size: 18px;
    margin: 10px 0 0 !important;
    cursor: pointer;
    min-height: 36px;
}

.practices h4:hover {
    color: #D06F2A;
}

.doctors h6 {
    min-height: 28px;
}
.practiceLocation div{
    min-height: 30px;
}

.practices h6 {
    font-family: 'SegoeUI-Semibold';
    font-size: 14px;
}

.practices .practiceImage {
    max-height: 100%;
    max-width: 100%;
    width: auto;
}

.ui.modal .practices .practiceImage {
    /*max-height: unset;*/
    max-height: 470px;
    max-width: 100%;
}

.ui.modal .practices .ui.ribbon.label {
    top: 20px;
}

.practices label, .practices a {
    font-size: 13px !important;
}

.practices a {
    text-decoration: none;
    color: #262626;
}

.practices a:hover {
    color: #707070;
}

.practices .icon {
    color: #D06F2A;
}

.practices .ui.ribbon.label {
    position: absolute;
    left: 2px;
    top: -50px;
    background: #D06F2A;
    border-radius: 0;
    color: #fff;
    border-color: #B15514;
    font-weight: normal;
    padding: 5px 15px;
}

.customDivider {
    border-top: 1px dashed #ddd !important;
    border-bottom: none !important;
    margin: 0 !important;
}

.btnPracticeView .button {
    background: #fff !important;
}

.btnPracticeView .button + .button {
    border-left: 1px solid rgba(34, 36, 38, 0.15);
}

.btnPracticeView .button.active {
    color: #D06F2A;
}

.btnPracticeView .button.active:hover {
    color: #D06F2A;
}

.modals .ui.card {
    width: auto;
}

button.gm-ui-hover-effect {
    top: 0 !important;
    right: 3px !important;
}

button.gm-ui-hover-effect img {
    width: 20px !important;
    height: 20px !important;
}

.openingHours span {
    font-family: 'SegoeUI-Semibold';
    min-width: 40px;
    float: left;
}

.openingHours label {
    margin-bottom: 4px;
}

.scroller {
    max-height: 80px;
    overflow-y: scroll;
}

.weather table {
    width: 100%;
}

.weather table td:last-child {
    border-right: none;
}

.weather table td {
    border-right: 1px solid #DDD;
    padding: 5px;
    font-size: 12px;
    text-align: center;
}

/*-----------------------------------------------------------Map------------------------------------------------*/
.gm-style .gm-style-iw-c {
    padding-left: 0 !important;
}

.nextDaysEvent {
    background: #eaf6fd;
    padding: 2px 2px;
}

.eventTitle {
    padding: 2px 2px;
}

.downloadApp-img1, .downloadApp-img2 {
    max-height: 100%;
    max-width: 100%;
}

.iphone-steps {
    margin-top: 30px;
}

.rbc-event {
    padding: 0px !important;
}

.rbc-row-segment {
    word-wrap: normal;
}

.care-delta .rbc-row-segment .rbc-event-content {
    white-space: nowrap !important;
}

.pr-sm-img {
    margin: 10px;
    max-height: 120px;
}

.selected-img {
    border: 2px solid #D06F2A;
    padding: 2px;
}

.sm-img-container {
    height: 150px;
    overflow-x: auto;
    width: 100%;
    display: inline-flex;
}

.next-btn, .prev-btn {
    font-size: 40px;
    position: absolute;
    top: 36%;
    color: #fff;
    width: 40px;
    cursor: pointer;
    opacity: 0.5;
}

.next-btn {
    right: 10px;

}

.prev-btn {
    left: 20px;
}

.next-btn .icon, .prev-btn .icon {
    color: #fff !important;
}

.next-btn:hover, .prev-btn:hover {
    opacity: 1;
}

.tooltip {
    position: relative !important;
    display: inline-block !important;
    opacity: unset !important;
    text-align: center;
    width: 100%;
    z-index: auto !important;
}

.rbc-row-content {
    z-index: auto !important;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 15px;

    /* Position the tooltip */
    position: absolute;
    z-index: 999;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.max-temp {
    color: #D06F2A;
}

.min-temp {
    color: #908C8C;
}

.time-span {
    float: right;
    color: #D06F2A;
}

.react-datepicker-wrapper, .react-datepicker-wrapper div {
    width: 100%;
}

.dentist-view-container .col-md-12 {
    margin-top: 10px;
}

.dentist-view-container {
    padding-bottom: 20px !important;
}

.document-area {
    border: 1px solid #DDDDDD;
    min-height: 200px;
    /*background: url('../images/camera.png') no-repeat;*/
    background-size: 40%;
    background-position: top;
    text-align: center;
}

.document-img {
    max-width: 100%;
    max-height: 200px;
}

.document-area .span-1 {
    position: absolute;
    bottom: 10px;
    left: 40px;
}

.document-area .span-2 {
    position: absolute;
    bottom: 10px;
    right: 40px;
}

.document-area .span-3 {
    /*position: absolute;*/
    top: 5px;
}

.document-area .input-group-btn {
    margin-bottom: 0px;
    cursor: pointer;
}

.icon-img {
    max-width: 100px;
}

.del-icon {
    cursor: pointer;
}

.expiredDoc {
    position: absolute;
    z-index: 9;
    left: 35px;
    top: 5%;
    opacity: 0.3;
}

.approvalDoc {
    position: absolute;
    z-index: 9;
    left: 35px;
    top: 50%;
    opacity: 0.3;
}

.document-pdf {
    width: 90%;
    height: 300px;
}

.dentist-document-pdf {
    width: 90%;
    height: 180px;
}

.help-files-pdf {
    width: 100%;
}

.crop-img-container {
    min-height: 200px;
}



/*-----------------------------------------------------------notification------------------------------------------------*/
.notification {
    position: relative;
    margin-right: 20px;
}

.notification .bellIcon {
    font-size: 16px !important;
    margin-top: 20px !important;
}

.notification .ui.label.circular.bellIconLabel {
    background: #d67d38 !important;
    color: #fff !important;
    box-shadow: none !important;
    position: absolute;
    min-height: 19px !important;
    min-width: 19px !important;
    line-height: 8px !important;
    left: 9px;
    top: 10px;
    padding: 5px !important;
}

.notification-list {
    width: 200px;
}

/*-----------------------------------------------------------Help------------------------------------------------*/
.helpMark {
    margin: -15px 20px 0 10px;
    float: right;
}

.helpList {
    padding-left: 15px;
}

.helpList li + li {
    margin-top: 15px;
    border-top: 1px solid #e1e1e1;
    padding-top: 10px;
}

.helpList li strong {
    font-size: 14px;
}

.helpList li span + span {
    margin-left: 30px;
}

.helpList li span:hover .linkText {
    text-decoration: underline;
}

.helpVideo {
    max-width: 100%;
}

.helpVideo + .helpVideo {
    margin-top: 20px;
}

.helpPdf iframe {
    width: 100%;
    min-height: 500px;
}
.helpPdf iframe + iframe{
    margin-top: 20px;
}

/*------------------------------FreezeTable----------------------*/
.FreezeTable {
  max-width: 100%;
  max-height: 60vh;
  overflow: scroll;
  position: relative;
}

.FreezeTable table {
  position: relative;
  border-collapse: collapse;
}

.FreezeTable thead th {
  position: sticky;
  top: 0;
}

.FreezeTable thead th:first-child {
  left: 0;
  z-index: 2;
}
.FreezeTable thead th:nth-child(2) {
  left: 90px;
  z-index: 2;
}
.FreezeTable thead th:nth-child(3) {
    left: 180px;
    z-index: 2;
}

.FreezeTable tbody td:first-child,
.FreezeTable tbody td:nth-child(2),
.FreezeTable tbody td:nth-child(3){
  position: sticky;
  background: #fff;
}
.FreezeTable tbody tr:nth-child(2n) td:first-child,
.FreezeTable tbody tr:nth-child(2n) td:nth-child(2),
.FreezeTable tbody tr:nth-child(2n) td:nth-child(3){
    background: #fcf5ef;
  }
.FreezeTable tbody td:first-child{
  left: 0;
    z-index: 1;
}
.FreezeTable tbody td:nth-child(2) {
  left: 90px;
    z-index: 1;
}
.FreezeTable tbody td:nth-child(3) {
    left: 180px;
    z-index: 1;
}

/*------------------------------------------------------Feedback-btn------------------------------------------------*/
.btnFeedback{
    width: 30px;
    height: 100px !important;
    background-color: #d16f26;
    border-radius: 10px 0 0 10px;
    z-index: 999;
    box-shadow: 0px 0px 15px rgba(68, 68, 68, 0.4) !important;
    text-align: center;
    color: #222;
    padding: 18px 0;
    position: fixed;
    right: 66px;
    cursor: pointer;
    top: 50%;
}
.btnFeedback small{
    font-size: 13px;
    color: #fff;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    letter-spacing: 0.5px;
}
.btnFeedback:hover{
    width: 40px;
    background-color: #c4631b;
}
.table {
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
}

/*-----------------------------------------------------------Responsive------------------------------------------------*/
@media (max-width: 1700px) {
    .table thead th {
        min-width: 170px;
    }
}

@media (max-width: 1500px) {
    .ui.card.practices {
        width: calc(33.33% - 15px);
    }

    .ui.card.practices:nth-child(3n+1) {
        clear: both;
    }

    .ui.card.practices:nth-child(4n+1) {
        clear: none;
    }
}
@media (max-width: 1280px) {
    #RBS-Scheduler-root{
        margin-top: 50px;
    }
    #RBS-Scheduler-root thead tr td .ant-row-flex {
        justify-content: start;
        position: fixed;
        margin: -30px 0 20px 0!important;
        right: unset;
        top: unset;
    }
}

@media (max-width: 1199px) {
    .cdp-udr .ui.visible.thin.left.sidebar ~ .fixed, .cdp-udr .ui.visible.thin.left.sidebar ~ .pusher {
        transform: none;
    }

    .cdp-udr .ui.visible.thin.left.sidebar ~ .pusher .ui.basic.segment {
        padding-right: 0;
    }

    .cdp-udr .ui.thin.left.sidebar ~ .pusher .ui.secondary.menu .item.toggle {
        margin-left: 0 !important;
    }

    .cdp-udr .ui.visible.thin.left.sidebar ~ .pusher .ui.secondary.menu .item.toggle {
        margin-left: 300px !important;
    }

    .ui.horizontal.list.topValues > .item {
        margin-left: 25px;
    }
}

@media (max-width: 1080px) {
    .ui.card.practices {
        width: calc(50% - 15px);
    }

    .ui.card.practices:nth-child(4n+1),
    .ui.card.practices:nth-child(3n+1) {
        clear: none;
    }

    .ui.card.practices:nth-child(2n+1) {
        clear: both;
    }
}

@media (max-width: 991px) {

    .ui.pointing.secondary.menu .item {
        padding: 20px 30px !important;
    }

    .ui.horizontal.list.topValues {
        text-align: right;
    }

    .leftSection {
        width: 300px;
        margin-right: 15px;
    }

    .rightSection {
        width: calc(100% - 315px);
    }
    .leftPanel{
        position: absolute;
        z-index: 99;
    }
    .filters .btnFilterToggle{
        float: right;
        margin-right: 0 !important;
    }
}

@media (max-width: 767px) {
    .containerMain {
        padding-top: 20px;
    }

    .login .sectionRight .row {
        align-items: unset;
    }

    .login .google {
        position: absolute;
        bottom: 110px;
        left: 50%;
        transform: translateX(-50%);
    }

    .login h1 {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 160px;
        color: #fff !important;
    }

    .login .downloadApp {
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        background: #d46e2e !important;
        color: #fff !important;
    }

    .login .downloadApp:hover {
        background: #d16230 !important;

    }

    .login .loginLogo {
        margin-top: 50px;
        width: 250px;
        margin-bottom: 0;
    }

    .ui.button {
        padding: 10px 20px !important;
    }

    .cdp-udr .btnNextPre .ui.button {
        padding: 10px !important;
    }

    .rbc-calendar .rbc-toolbar .rbc-btn-group:first-child button + button {
        height: 34px;
        width: 34px;
    }

    .rbc-calendar .rbc-toolbar .rbc-btn-group:first-child button:first-child {
        padding: 10px 20px;
    }

    .rbc-calendar .rbc-toolbar .rbc-btn-group:last-child button {
        padding: 9px 12px
    }

    .cdp-udr .ui.thin.left.sidebar ~ .fixed, .cdp-udr .ui.thin.left.sidebar ~ .pusher {
        transform: translate3d(0, 0px, 0px);
    }

    .cdp-udr .ui.visible.thin.left.sidebar ~ .fixed, .cdp-udr .ui.visible.thin.left.sidebar ~ .pusher {
        /*transform: translate3d(300px, 0px, 0px);*/
        transform: none;
    }

    .cdp-udr .ui.segment {
        padding: 0;
    }

    .cdp-udr .ui.thin.left.sidebar {
        width: 0;
    }

    .rbc-event-content, .rbc-event-content sub {
        font-size: 8px;
    }

    .rbc-event-content span {
        display: block;
    }

    .rbc-event {
        border-radius: 0 !important;
    }

    .rbc-row-segment {
        line-height: normal;
    }

    .calendar-container .rbc-calendar {
        /*min-height: 600px;*/
    }

    .login .sectionLeft {
        display: none;
    }

    .login .sectionRight {
        height: 100vh;
        background: url("../images/LoginBgMobile.jpg") no-repeat;
        background-size: cover;
        background-position: 50%;
    }

    .cdp-udr .ui.card .content, .ui.card .content.header-style {
        padding: 10px;
    }

    .ui.modal .actions {
        padding: 15px 8px !important;
    }

    .ui.modal .header {
        padding: 15px 8px !important;
    }

    .cdp-udr .ui.secondary.menu .item.toggle {
        position: absolute;
        z-index: 1;
    }

    .rbc-calendar .rbc-header {
        font-size: 13px;
        padding: 8px 2px !important;
    }

    .cdp-udr .ui.secondary.menu .logoSmall {
        display: block;
        margin: 8px 0 8px 62px;
        float: left;
    }

    .cdp-udr .ui.secondary.menu .item.toggle:hover {
        background: #fff;
    }

    .ui.modal .content > .description {
        padding: 0 !important;
    }

    .ui.modal .content > .description.mt20 {
        margin-top: 15px !important;
    }

    .ui.modal .table {
        margin-bottom: 0 !important;
    }

    .table {
        margin-top: 10px;
    }

    .table td {
        padding: 8px 0.75rem;
    }

    .table thead th > div {
        min-height: 34px;
    }

    .leftSection {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        margin-top: 15px;
    }

    .rightSection {
        width: 100%;
    }

    .cdp-udr .ui.thin.left.sidebar.visible .logo {
        width: 180px;
        padding: 15px 0;
    }

    .cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > a > .icon, .cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .title .icon {
        font-size: 14px;
    }

    .ui.modal.familyView > .content {
        padding: 15px 0 !important;
    }

    .ui.modal > .content {
        padding: 15px !important;
    }

    .ui.modal > .content.pt-0 {
        padding-top: 0 !important;
    }

    .ui.modal .practiceImage {
        max-height: 200px !important;
        width: auto;
        margin-bottom: 15px;
    }

    .cdp-udr .gm-style-iw-d .ui.card .content {
        padding: 0 15px !important;
    }
    .btnFeedback{
        right: 0;
    }
}

@media (max-width: 766px) {
    .rbc-calendar .rbc-toolbar .rbc-toolbar-label {
        font-size: 18px;
    }

    h3 {
        font-size: 18px;
    }
}

@media (max-width: 640px) {
    .rbc-calendar .rbc-toolbar {
        display: block;
    }

    .rbc-calendar .rbc-toolbar .rbc-btn-group:last-child {
        display: block;
        text-align: right;
        margin-top: 10px;
    }

}

@media (max-width: 575px) {
    .containerMain {
        padding-top: 15px;
    }

    .cdp-udr .ui.huge.header {
        font-size: 16px;
    }

    .cdp-udr .ui.huge.header span + span {
        font-size: 15px;
    }

    .practiceTop, .careTop {
        width: 100%;
        text-align: right;
    }

    .practiceTop .ui.input {
        float: left;
        width: 100%;
        margin-top: 10px;
    }

    .practiceTop .ui.dropdown {
        margin-right: 0 !important;
    }

    .careTop .ui.horizontal.list > .item:first-child,
    .careTop .ui.horizontal.list > .item:first-child .ui.dropown {
        width: 100%;
    }

    .ui.horizontal.list.topValues {
        text-align: left;
    }

    .ui.horizontal.list.topValues > .item {
        margin-left: 0;
    }

    .careTop .ui.horizontal.list > .item + .item,
    .payOrthoTop .ui.horizontal.list > .item + .item {
        width: 50%;
    }

    .ui.horizontal.list > .item + .item {
        margin-top: 10px;
    }

    .topDropdown, .topDropdown .ui.dropdown,
    .topDropdown .ui.input {
        width: 100%;
        margin-right: 0;
    }

    .topDropdown .ui.buttons .ui.dropdown {
        width: auto;
    }

    .payOrthoTop .ui.horizontal.list.topValues > .item:first-child {
        width: 100%;
    }

    /*.cdp-udr .ui.secondary.menu {*/
    /*height: auto;*/
    /*display: flow-root;*/
    /*}*/
    /*.cdp-udr .ui.secondary.menu .item {*/
    /*color: #273140;*/
    /*padding: 21px 25px;*/
    /*margin: 0;*/
    /*}*/
    /*.cdp-udr .ui.secondary.menu .item:hover {*/
    /*color: #273140 !important;*/
    /*}*/
    /*.cdp-udr .ui.secondary.menu .icon.setting {*/
    /*width: auto;*/
    /*}*/
    /*.cdp-udr .ui.secondary.menu .dropdown:hover {*/
    /*background: #fff;*/
    /*}*/
    /*.cdp-udr .ui.secondary.menu .dropdown .menu {*/
    /*border-radius: 0 0 4px 4px !important;*/
    /*left: -80% !important;*/
    /*margin-top: 0 !important;*/
    /*}*/
    /*.cdp-udr .ui.secondary.menu .dropdown.user {*/
    /*padding: 16px;*/
    /*}*/
    /*.cdp-udr .ui.secondary.menu .dropdown.user .text {*/
    /*margin-top: 4px;*/
    /*}*/
    /*.cdp-udr .ui.secondary.menu .right.menu .item {*/
    /*padding: 21px 20px;*/
    /*}*/
    /*.cdp-udr .ui.secondary.menu .right.menu #search {*/
    /*display: none;*/
    /*}*/
    .cdp-udr.ui.card .content .ui.form, .ui.card .content.header-style .ui.form, .cdp-udr .ui.card .content .ui.fluid.selection.dropdown, .cdp-udr .ui.card .content.header-style .ui.fluid.selection.dropdown {
        width: 100%;
    }

    .content-left {
        width: 100%;
    }

    .ui.huge.header.bgColor-Yellow span:nth-child(2) {
        display: none;
    }

    .ui.huge.header.bgColor-Yellow span:nth-child(3) {
        display: block;
    }

    .ui.card.patientDetails i.user {
        display: none;
    }

    .ui.card.patientDetails .row:nth-child(n+2) .col-6:first-child, .ui.card.patientDetails .row:nth-child(n+2) .col-8 {
        padding-left: 10px;
    }

    .questions select, .questions .ui.input input, .questions .ui.input {
        width: 100% !important;
    }

    .ui.steps i.icon {
        font-size: 36px !important;
    }

    .col.dropDown {
        flex-basis: 100%;
    }

    .ui.mini.modal {
        width: 348px !important;
    }

    .filter .location, .filter .day, .filter .appointment {
        width: 100%;
        margin-top: 15px;
    }

    .filter .location div:first-child, .filter .day div:first-child, .filter .appointment div:first-child {
        width: 100%;
    }

    /*.ui.pointing.secondary.menu .item + .item, .ui.pointing.secondary.menu .item + .item.active:hover {*/
    /*border-left: none !important;*/
    /*border-top: 1px solid rgba(34, 36, 38, 0.15) !important;;*/
    /*}*/
    .ui.pointing.secondary.menu .item {
        font-size: 0;
        padding: 15px 30px !important;
    }

    .ui.pointing.secondary.menu .item i.icon {
        font-size: 16px;
        margin-right: 0;
    }

    .cdp-udr .ui.thin.left.sidebar.visible {
        width: 240px;
    }

    .cdp-udr .ui.visible.thin.left.sidebar ~ .pusher .ui.secondary.menu .item.toggle {
        margin-left: 240px !important;
    }

    .ui.card.practices {
        width: calc(100% - 15px);
    }

    .__react_component_tooltip {
        /*width: 100%;*/
        left: 5px !important;
        right: 5px !important;
        padding: 10px !important;
    }

    .patientInfo label {
        margin-bottom: 0;
    }

    .patientInfo p {
        font-size: 13px;
    }

    .familyView .card {
        border: none;
    }

    .familyView .card .card-content {
        padding: 5px 0 0 0;
    }

    .familyView .card .card-header {
        padding: 0;
        background: transparent !important;
        border-bottom: none;
    }

    .familyView .tableCustomStyle {
        margin-top: 5px !important;
    }

    h3.headingSub {
        color: #262626 !important;
    }

    .table thead th {
        min-width: 90px !important;
        padding: 2px 6px
    }

    .table td {
        padding: 8px 6px;
    }

    h3 {
        font-size: 16px;
    }

    h2 {
        font-size: 22px;
    }

    .rbc-calendar .rbc-time-view .rbc-header {
        font-size: 11px;
    }

    .rbc-calendar .rbc-time-view .rbc-header span {
        line-height: 24px;
    }

    .rbc-calendar .rbc-toolbar .rbc-toolbar-label {
        font-size: 15px;
        margin-left: 5px;
    }
    .topFilters, .topFilters .ui.dropdown, .topFilters .ui.input, .filters, .filters .ui.dropdown, .filters .ui.input {
        width: 100%;
        margin-right: 0!important;
    }
}

/* ------------Claims View --------------- */
.claims-container {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    grid-template-rows: 200px 6fr;
    grid-template-areas: "controls controls ."
                          "claimstable claimstable claimstable";
    padding: 10px;
    background: #FFFFFF;
}

.claims-controls {
 grid-area: controls;
 padding-left :10px; 
}

.claims-table {
    grid-area: claimstable;
    overflow-y: hidden;
}

/*------------------------------members------------------------------  */

.members-container {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    grid-template-rows: 100px  6fr;
    grid-template-areas: "members-controls members-controls members-controls "
                          "memberstable memberstable memberstable";
    padding: 10px;
    background: #FFFFFF;
}

.members-controls {
    grid-area: members-controls;
    padding:10px; 
   }
   
.memberstable {
       grid-area: memberstable;
       overflow-y: hidden;
   }
.cdp-udr .ui .card  .members-container {
    padding: 10px;
}
@media (max-width: 420px) {
    .cdp-udr .ui.secondary.menu .right.menu .item {
        padding: 21px 10px;
    }

    .cdp-udr .ui.secondary.menu .dropdown.user {
        padding: 17px 0px;
    }

    .cdp-udr .ui.secondary.menu .dropdown.user .text {
        margin-top: 4px;
    }

    .btnSend {
        float: right;
    }

}

@media (min-width: 768px) {
    .doctorList {
        margin-bottom: 15px;
    }
}

@media (min-width: 778px) {
    .downloadApp {
        display: none !important;
    }

    .mobile-help-link {
        display: none !important;
    }
}

@media (max-width: 375px) {
    .careTop .ui.horizontal.list.topValues > .item span b {
        display: block;
    }

    .user .userName {
        display: none;
    }

    .openingHours span {
        min-width: 34px;
    }
}

@media (max-width: 360px) {
    .openingHours span {
        min-width: 50px;
    }

    .openingHours .col-6 {
        width: 100% !important;
        flex: 100%;
        max-width: 100% !important;
    }

    .openingHours .col-6.pl-2 {
        padding-left: 15px !important;
    }

    .openingHours .col-6.pl-2 label {
        border-top: 1px dashed #ddd;
        width: 100%;
    }
}
.card {
    background: #fff;
    border-radius: 4px;
    padding: 15px;
    border-bottom: 1px solid #d58234;
}
.campaign-filters label {
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: 3px;
}
.campaign-filters input, .campaign-filters .form-group {
    float: left;
    width: 100%;
}
.campaign-filters .form-group {
    float: left;
    width: 100%;
}
.filters input, .campaign-filters input {
    height: 36px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
}
.campaign-filters input[type="checkbox"] {
    height: unset;
}
.width120 {
    width: 120px;
}
.campaign-filters input[type="file"] {
  border: none;
}
.vid-img-dlt {
  max-height: 150px;
  max-width: 80%;
}
.ht-200 {
  height: 200px;
}
.addMore {
  font-size: 20px;
  font-weight: 600;
  color: #d67d38;
  cursor: pointer;
}
.repeatChkBox {
  float: left;
  margin: 10px 15px 0px 0px;
}
.repeatChkBox input[type="checkbox"] {
  margin-right: 5px;
}
.campaign-filters input[type="checkbox"] {
  width: unset;
}
.capitalize {
  text-transform: capitalize;
}
.modalCenter {
    margin: 0 auto !important;
    margin-top: 20px !important;
}
.clickable {
    cursor: pointer;
}
.resource-view {
    display: none;
}
@supports (-webkit-touch-callout: none) {
    .content-right{
        padding-top: 30px;
    }
  }

.text-ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.d-flex{
    display: flex;
}

.login-with-google-btns {
    transition:
            background-color 0.3s,
            box-shadow 0.3s;
    cursor: pointer;

    padding: 12px 16px 12px 42px;
    border: none;
    border-radius: 3px;
    box-shadow:
            0 -1px 0 rgba(0, 0, 0, 0.04),
            0 1px 1px rgba(0, 0, 0, 0.25);

    color: #757575;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 12px 11px;
}

/*------------------------------start - provider-days------------------------------  */

#table-provider-days{
    table-layout: fixed;
    padding-bottom: 50px;
}

#table-provider-days th:nth-child(1){
    width: 70px;
}

#table-provider-days th:nth-child(2){
    width: 65px;
}

#table-provider-days th:nth-child(n+3) /* Select all columns starting from the third column */
{
    width: 140px; /* Adjust the width as needed */
    word-wrap: break-word;
}
#table-provider-days thead{
    position: sticky;
    top: 0;
    z-index: 1;
}
/*------------------------------end - provider-days------------------------------  */
